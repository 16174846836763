export const global = {
  body: {
    w: '100%',
    bg: 'pmpurple.2',
  },

  // styles for the `a`
  a: {
    color: 'pmpurple.14',
    _hover: {
      textDecoration: 'underline',
    },
  },
};
